<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md5 class="text-center">
        <img src="../../assets/images/uno-logo.png" class="form-head-logo mb-2" />
        <v-card class="elevation-12">
          <offset-heading titleClass="text-sm-center font-weight-bold" title="LOGIN"></offset-heading>
          <v-card-text class="px-5">
            <v-form ref="loginForm" lazy-validation>
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                prepend-icon="fas fa-user-circle"
                label="Login"
                required
                aria-autocomplete="current-password"
                validate-on-blur
                @keyup.enter="login($event)"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                :rules="passwordRules"
                prepend-icon="lock"
                name="password"
                label="Password"
                id="password"
                autocomplete="on"
                validate-on-blur
                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @keyup.enter="login($event)"
              ></v-text-field>
              <div class="px-5">
                <v-layout row wrap justify-center>
                  <v-btn submit color="primary" d-inline-flex fab medium @click="login($event)">
                    <v-icon dark>fas fa-long-arrow-alt-right</v-icon>
                  </v-btn>
                </v-layout>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      persistent
      eager
      v-model="businessModal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="align-v-center cover-bg">
          <v-container grid-list-md text-xs-center>
            <v-layout align-center justify-center row>
              <v-flex sm12 md12>
                <v-card class="elevation-12 pb-4 mt-8">
                  <v-card-title class="primary text-white">
                    ACCOUNTS
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      slot="action"
                      @click="logout()"
                      fab
                      color="white"
                      :class="$vuetify.breakpoint.width > 960 ? 'ml-0 mr-0' : 'ml-0'"
                    >
                      <v-icon dark class="primary--text">fas fa-sign-out-alt</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle
                    class="primary white--text"
                  >Select the account that you would like to access</v-card-subtitle>

                  <div class="px-4 mx-1 mt-7 pt-7" v-if="vg_businessesList.length == 0">
                    <p>Sorry you dont have any associated business. Please contact support.</p>
                  </div>
                  <div class="px-4 mx-1 mt-7 pt-7" v-else>
                    <v-layout row wrap justify-space-between>
                      <v-flex
                        px-4
                        sm12
                        xs12
                        md6
                        lg6
                        v-bind:key="index"
                        v-for="(account, index) in vg_businessesList"
                      >
                        <v-card
                          hover
                          class="elevation-12 mb-4 pointer account-list"
                          v-on:click.prevent="setActiveBusiness(account)"
                        >
                          <div class="c-layout">
                            <div class="cl-flex6">
                              <div class="min-h-80 v-center bg-grey">
                                <img src="@/assets/images/uno-logo.png" class="account-logo" />
                              </div>
                            </div>
                            <div class="cl-flex6">
                              <div class="min-h-80 section-excerpt v-center">
                                <h2 class="font-weight-bold title">{{ account.name }}</h2>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  async mounted () {
    if (this.$route.query.token) {
      this.va_clearAuthState()
      this.spinner(true)
      this.va_loginWithToken(this.$route.query.token).then(async (s) => {
        try {
          await this.va_setBusinessListNotLoggedIn(this.$route.query.token)
        } catch (error) {
          return
        }
        try {
          await this.setActiveBusiness(s.active_business)
        } catch (error) {
          return
        }
        this.spinner(false)
      }).catch((e) => {
        console.log(e)
        this.spinner(false)
        return e
      })
    }
    this.spinner(false)
  },
  data () {
    return {
      showPassword: false,
      user: {
        email: '',
        password: ''
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^.+@.+\..+$/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be more than 8 characters'
      ],
      passwordCheckRules: [v => !!v || 'Password is required'],
      businessModal: false
    }
  },
  methods: {
    ...mapActions({
      va_login: 'auth/loginUser',
      va_loginWithToken: 'auth/loginWithToken',
      va_setBusinessesList: 'auth/setUserBusinessesList',
      va_setBusinessListNotLoggedIn: 'auth/setUserBusinessesListNotLoggedIn',
      va_setActiveBusiness: 'auth/setActiveBusiness',
      va_setActiveBusinessBranding: 'auth/setActiveBusinessBranding',
      va_clearAuthState: 'auth/clearState'
    }),
    async login () {
      if (!this.$refs.loginForm.validate()) return
      this.spinner(true)
      try {
        await this.va_login({ email: this.user.email, password: this.user.password })
      } catch (error) {
        console.log(error)
        return
      }
      try {
        await this.va_setBusinessesList()
      } catch (error) {
        this.spinner(false)
        console.log(error)
        return
      }
      this.businessModal = true
      this.spinner(false)
    },
    async setActiveBusiness (business) {
      this.spinner(true)
      try {
        await this.va_setActiveBusiness(business)
      } catch (error) {
        console.log(error)
        return
      }
      try {
        await this.va_setActiveBusinessBranding(business.id)
      } catch (error) {
        return
      }
      this.$vuetify.theme.themes.light.primary = this.vg_activeBusinessBrandingAndLogo.primary
      this.$vuetify.theme.themes.light.secondary = this.vg_activeBusinessBrandingAndLogo.secondary
      this.$vuetify.theme.themes.light.accent = this.vg_activeBusinessBrandingAndLogo.accent
      this.businessModal = false
      this.$router.push({ name: 'Applications' })
      this.spinner(false)
    },
    logout () {
      this.va_clearAuthState()
      this.businessModal = false
    }
  },
  computed: {
    ...mapGetters({
      vg_businessesList: 'auth/businessesList',
      vg_activeBusinessBrandingAndLogo: 'auth/activeBusinessBrandingAndLogo'
    })
  },
  watch: {},
  beforeDestroy () { }
}
</script>

<style lang="scss" scoped>
</style>
